<template>
  <ion-page>
    <ion-header>
      <ion-toolbar class="toolbar-header">
        <ion-icon
          @click="goBack"
          size="large"
          color="primary"
          slot="start"
          :icon="chevronBackOutline"
        ></ion-icon>
        <ion-label class="fw-600">{{ $t('select_payment') }}</ion-label>
      </ion-toolbar>
    </ion-header>
    <skeleton-select-payment v-if="loadingInit" />
    <ion-content v-else>
      <ion-grid class="mb-2">
        <ion-row class="ion-align-items-center">
          <ion-col>
            <ion-row>
              <div class="d-flex align-center ml-1">
                <ion-icon class="i-help" :icon="helpCircleOutline" @click="openHelp"></ion-icon>
                <ion-label class="fs-2 ml-1 fw-500 text-gray-700">
                  {{ $t('use_available_credits') }}
                </ion-label>
              </div>
            </ion-row>
            <ion-row>
              <ion-label
                class="ml-6 mt-1 fw-500"
                :class="{
                  'text-primary-orange-500': remainingCredits <= 0,
                  'text-primary-green-500': remainingCredits > 0
                }"
                >{{ $t('remaining_credits') }}
                {{ priceFormatter(currencySymbol, remainingCredits) }}</ion-label
              >
            </ion-row>
          </ion-col>
          <ion-col size="auto">
            <ion-toggle
              ref="toggleRef"
              :value="isChecked"
              :checked="isChecked"
              @ionChange="toggleCredits"
              :disabled="isCreditAvailable"
            ></ion-toggle>
          </ion-col>
        </ion-row>
      </ion-grid>
      <div class="line mb-3"></div>
      <card-payment
        ref="cardPaymentRef"
        @onChangePaymentType="onPaymentSelected"
        @setOpenModalAddCard="setOpenModalAddCard(true)"
        @addNetsBankCard="addNetsBankCard"
        :listCards="listCardSortDefault"
        :isOpenFormNetsPayment="isOpenFormNetsPayment"
        :isSgMyUser="isSgMyUser"
        :isSgUserOnly="isSgUserOnly"
        :isFullyPaidCustBalance="isFullyPaidCustBalance"
      />
      <div class="line mb-3"></div>
      <summary-info
        :selectedInvoices="selectedInvoicesData"
        :currencySymbol="currencySymbol"
        :customerCreditAllocation="customerCreditAllocation"
        :totalInvoicesAmount="totalInvoicesAmount"
        :grandTotal="grandTotal"
        :totalPaidAmount="totalPaidAmount"
      />
      <div class="line mb-3"></div>
    </ion-content>

    <skeleton-bottom v-if="loadingInit" />
    <bottom-content
      v-else
      @handlePayInvoices="enterPayment"
      :totalPaidAmount="totalPaidAmount"
      :currencySymbol="currencySymbol"
      :paymentType="paymentType"
      :isDigitalPayment="isDigitalPayment"
      :isFullyPaidCustBalance="isFullyPaidCustBalance"
    />
    <ion-modal
      :initial-breakpoint="0.7"
      :is-open="toggleInputCredit"
      :backdropDismiss="true"
      @didDismiss="toggleCredits"
      class="bor-15"
    >
      <input-credits
        @close-modal="toggleInputCredit = false"
        @onUseCreditBalance="onUseCreditBalance"
        :currencySymbol="currencySymbol"
        :initialCredit="initialCreditValue"
        :remainingCredits="remainingCredits"
      />
    </ion-modal>
    <ion-modal
      :is-open="isOpenAddCardPayment"
      css-class="modal-add-card"
      @didDismiss="setOpenModalAddCard(false)"
    >
      <modal-add-card
        :isEdit="false"
        :infoCard="null"
        :isNetCard="listNetsCard.length > 0"
        :isDefaultCard="false"
        @add-card="addDigitalCard"
        @close-modal="setOpenModalAddCard(false)"
      ></modal-add-card>
    </ion-modal>
    <ion-modal :is-open="isDigitalPaymentSummaryOpen" :backdropDismiss="true">
      <payment-summary
        @viewQrCode="viewQrCode"
        @cancelPaymentOrder="cancelPaymentOrder"
        @backToInvoice="backToInvoice"
        @backToHome="backToHome"
        :currencySymbol="currencySymbol"
        :paymentSummary="paymentSummary"
        :invoices="selectedInvoicesData"
        :paymentType="paymentType"
        :appliedCredit="customerCreditAllocation"
        :grandTotal="grandTotal"
        :totalPaidAmount="totalPaidAmount"
      />
    </ion-modal>
    <ion-toast
      :is-open="isErrorCreditAllocation"
      mode="ios"
      color="danger"
      :message="errorCreditAllocationMessage"
      :duration="2000"
      position="top"
      @didDismiss="isErrorCreditAllocation = false"
    >
    </ion-toast>
    <ion-loading
      :is-open="isLoadingAddCard"
      cssClass="custom-loading"
      message=""
      spinner="crescent"
      @didDismiss="setLoadingAddCard(false)"
    >
    </ion-loading>
  </ion-page>
</template>
<script>
// packages
import { apolloClient } from '@/main';
import { addStripeCard, cancelPrepayment } from '@/modules/b2b/services/graphql';
import { ACTIONS } from '@/modules/b2b/store/payment/actions';
import ModalAddCard from '@/modules/b2b/views/account/Account/components/partials/ModalAddCard.vue';
import {
  INVOICE_PAYMENT_METHOD,
  INVOICE_PAYMENT_TYPE,
  TOAST_COLOR,
  USER_COUNTRY
} from '@/modules/shared/constants/';
import { openToast, useLoading } from '@/modules/shared/utils/';
import handleCallApi from '@/modules/shared/utils/services.js';
// import Payment from '@/plugins/nets-payment-plugin.js';
import Clevertap from '@/services/shared/helper/clevertap';
import { priceFormatter } from '@/utils/';
import { Device } from '@capacitor/device';
import { alertController, isPlatform } from '@ionic/vue';
import { chevronBackOutline, helpCircleOutline } from 'ionicons/icons';
import { defineComponent, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';
import { createNamespacedHelpers } from 'vuex';

// components
import {
  InputCredits,
  SkeletonBottom,
  SkeletonSelectPayment
} from '@/modules/shared/components/invoices-payment';
import { BottomContent, CardPayment, PaymentSummary, SummaryInfo } from './components';

const { mapGetters, mapActions } = createNamespacedHelpers('b2b/payment');

export default defineComponent({
  name: 'select-payment-b2b',
  inject: ['$storage'],
  components: {
    BottomContent,
    SummaryInfo,
    CardPayment,
    InputCredits,
    ModalAddCard,
    SkeletonBottom,
    SkeletonSelectPayment,
    PaymentSummary
  },
  setup() {
    const router = useRouter();
    const { t } = useI18n();
    const { createLoading, dismissLoading } = useLoading();
    const isOpenAddCardPayment = ref(false);
    const toggleInputCredit = ref(false);
    const isChecked = ref(false);
    const isDigitalPaymentSummaryOpen = ref(false);
    const isErrorCreditAllocation = ref(false);
    const errorCreditAllocationMessage = ref('');
    const isLoadingAddCard = ref(false);
    const loadingInit = ref(false);
    const setOpenLoadingInit = (state) => {
      loadingInit.value = state;
    };
    const setLoadingAddCard = (state) => {
      isLoadingAddCard.value = state;
    };
    const setDigitalPaymentSummaryOpen = (state) => {
      isDigitalPaymentSummaryOpen.value = state;
    };
    const goBack = () => {
      router.back();
    };
    const alert = async (cssClass, header, message = '', buttons) => {
      const alert = await alertController.create({
        backdropDismiss: false,
        cssClass,
        mode: 'ios',
        header: t(header),
        message: message ? t(message) : '',
        buttons
      });
      return alert.present();
    };
    const openHelp = alert.bind(
      this,
      'alert-blacklist-order',
      'not_sure_avail_credit',
      'not_sure_credit_desc',
      [
        {
          text: `${t('contact_us')}`,
          handler: () => {
            console.log('admin number');
          }
        },
        {
          text: t('close'),
          role: 'cancel'
        }
      ]
    );
    const setOpenModalAddCard = (value) => {
      isOpenAddCardPayment.value = value;
    };
    const backToInvoice = () => {
      setDigitalPaymentSummaryOpen(false);
      router.replace('/b2b/invoices/select-invoices');
    };
    const backToHome = () => {
      setDigitalPaymentSummaryOpen(false);
      router.replace('/b2b/main/home');
    };
    return {
      alert,
      goBack,
      backToHome,
      backToInvoice,
      chevronBackOutline,
      toggleInputCredit,
      helpCircleOutline,
      isChecked,
      isDigitalPaymentSummaryOpen,
      setDigitalPaymentSummaryOpen,
      openHelp,
      priceFormatter,
      isErrorCreditAllocation,
      errorCreditAllocationMessage,
      INVOICE_PAYMENT_TYPE,
      setOpenModalAddCard,
      isOpenAddCardPayment,
      isLoadingAddCard,
      setLoadingAddCard,
      createLoading,
      dismissLoading,
      customerCreditAllocation: ref(0),
      totalOverpayment: ref(0),
      totalOverdue: ref(0),
      totalPaidAmount: ref(0),
      selectedInvoicesData: ref([]),
      paymentType: ref(null),
      company: ref(null),
      user: ref(null),
      customer: ref(null),
      deviceId: ref(''),
      loadingInit,
      isOpenFormNetsPayment: ref(false),
      digitalCardIdSelected: ref(null),
      setOpenLoadingInit,
      isFullyPaidCustBalance: ref(false),
      remainingCredits: ref(0),
      listCardSortDefault: ref([])
    };
  },
  watch: {
    customerCreditAllocation(value) {
      this.updateRemainingBalance(value);
      if (value > 0 && !this.paymentType) {
        this.paymentType = INVOICE_PAYMENT_TYPE.CUSTOMER_CREDIT;
      }
      if (value === +this.totalInvoicesAmount.toFixed(2)) {
        this.isFullyPaidCustBalance = true;
        this.paymentType = INVOICE_PAYMENT_TYPE.CUSTOMER_CREDIT;
        this.$refs.cardPaymentRef.paymentType = INVOICE_PAYMENT_TYPE.CUSTOMER_CREDIT;
        this.$refs.cardPaymentRef.digitalCardIdSelected = null;
      } else {
        this.isFullyPaidCustBalance = false;
      }
    },
    isChecked(value) {
      this.isChecked = value;
    },
    selectedInvoicesData(value) {
      this.customerCreditAllocation = 0;
      this.$nextTick(() => {
        if (this.$refs.toggleRef) {
          this.$refs.toggleRef.$el.checked = false;
        }
      });
      this.selectedInvoicesData = value;
    },
    totalPaidAmount(value) {
      this.totalPaidAmount = Number(value);
    },
    paymentType(value) {
      if (this.isDigitalPayment) {
        this.totalPaidAmount = this.grandTotal;
      }
      this.paymentType = value;
    }
  },
  ionViewDidEnter() {
    this.remainingCredits = this.maximumCreditAmount;
    this.totalPaidAmount = this.grandTotal;
    this.selectedInvoicesData = this.selectedInvoices;
    this.paymentType = null;
    this.$nextTick(() => {
      if (this.$refs.cardPaymentRef) {
        this.$refs.cardPaymentRef.paymentType = null;
        this.$refs.cardPaymentRef.digitalCardIdSelected = null;
      }
      if (this.$refs.toggleRef) {
        this.$refs.toggleRef.$el.checked = false;
      }
    });
  },
  async ionViewDidLeave() {
    await this.$storage.removeCountDownTime();
  },
  async mounted() {
    this.deviceId = (await Device.getId()).uuid;
    this.setOpenLoadingInit(true);
    this.user = await this.$storage.getUser();
    this.company = await this.$storage.getSelectedCompany();
    this.customer = await this.$storage.getSelectCustomer();
    await this.handleGetCard();
  },
  computed: {
    ...mapGetters([
      'selectedPaymentInvoices',
      'cardList',
      'defaultCardId',
      'loading',
      'error',
      'status',
      'listNetsCard',
      'paymentSummary'
    ]),

    isSgMyUser() {
      return [USER_COUNTRY.SG, USER_COUNTRY.MY].includes(this.user?.country.id);
    },
    isSgUserOnly() {
      return this.user?.country.id === USER_COUNTRY.SG;
    },
    isCashAvailable() {
      return this.selectedInvoices?.length === 1 ? true : false;
    },
    selectedInvoices() {
      return this.selectedPaymentInvoices?.selectedInvoices ?? [];
    },
    totalInvoicesAmount() {
      return this.selectedPaymentInvoices?.totalInvoiceAmount;
    },
    currencySymbol() {
      return this.selectedPaymentInvoices?.selectedInvoices[0]?.currencySymbol ?? 'S$';
    },
    maximumCreditAmount() {
      return +this.selectedPaymentInvoices?.availableCreditAmount;
    },
    initialCreditValue() {
      if (this.maximumCreditAmount > this.totalInvoicesAmount) {
        return this.totalInvoicesAmount;
      } else {
        return this.selectedPaymentInvoices?.availableCreditAmount - this.customerCreditAllocation;
      }
    },
    grandTotal() {
      return +this.totalInvoicesAmount.toFixed(2) - this.customerCreditAllocation;
    },
    isCreditAvailable() {
      return this.selectedPaymentInvoices?.maximumCreditAmount > 0;
    },
    isDigitalPayment() {
      return INVOICE_PAYMENT_METHOD.DIGITAL.includes(this.paymentType);
    },
    isPaynowSelected() {
      return this.paymentType === INVOICE_PAYMENT_TYPE.PAYNOW;
    }
  },
  methods: {
    ...mapActions([
      ACTIONS.GET_LIST_CUSTOMER_CARD,
      ACTIONS.GET_LIST_NETS_CARD,
      ACTIONS.GET_NETS_MERCHANT_TOKEN,
      ACTIONS.GET_DEFAULT_CARD,
      ACTIONS.SET_DEFAULT_CARD,
      ACTIONS.ADD_STRIPE_CARD,
      ACTIONS.SET_SELECTED_PAYMENT_INVOICES,
      ACTIONS.CREATE_AUTOMATIC_BATCH_PAYMENTS
    ]),
    toggleCredits(event) {
      if (event.type === 'did-dismiss') {
        if (this.customerCreditAllocation === 0) {
          this.$refs.toggleRef.$el.checked = false;
        }
        return;
      }
      this.toggleInputCredit = event.detail.checked;
      this.isChecked = event.detail.checked;

      if (!this.isChecked) this.customerCreditAllocation = 0;
      if (this.customerCreditAllocation > 0) this.isChecked = true;
      else {
        this.remainingCredits = this.maximumCreditAmount;
        this.isChecked = false;
      }
    },
    onUseCreditBalance(amount) {
      if (+amount > this.remainingCredits) {
        this.isErrorCreditAllocation = true;
        this.errorCreditAllocationMessage = this.$t('input_amount_error');
        return;
      }
      if (+amount > +this.grandTotal.toFixed(2)) {
        this.isErrorCreditAllocation = true;
        this.errorCreditAllocationMessage = this.$t('input_amount_error_2');
        return;
      }
      if (+amount < 0) {
        this.isErrorCreditAllocation = true;
        this.errorCreditAllocationMessage = this.$t('invalid_amount_negative');
        return;
      }
      this.isErrorCreditAllocation = false;
      this.customerCreditAllocation = Number(amount);
    },
    onPaymentSelected(paymentType, digitalCardIdSelected) {
      this.paymentType = paymentType;
      this.digitalCardIdSelected = digitalCardIdSelected;
    },
    updateRemainingBalance(value) {
      this.remainingCredits = this.maximumCreditAmount - value;
      this.totalPaidAmount = this.grandTotal;
    },

    async addDigitalCard(card, isDefault) {
      this.setLoadingAddCard(true);
      const customerId = {
        customerId: this.company.id
      };
      const params = { ...card, ...customerId };
      const payload = {
        isQuery: false,
        services: addStripeCard,
        variables: params
      };
      let toastMessage;
      try {
        const data = await handleCallApi(payload);
        const err = data?.addCardByCustomer?.type ?? false;
        if (err) {
          toastMessage = {
            message: data?.addCardByCustomer?.message ?? '',
            color: TOAST_COLOR.DANGER
          };
        } else {
          toastMessage = {
            color: TOAST_COLOR.BLACK,
            message: this.$t('accountPage.added_card_successfully')
          };
        }
        if (isDefault)
          await this[ACTIONS.SET_DEFAULT_CARD]({
            cardId: data.addCardByCustomer.cardId,
            customerId: this.company.id
          });
        await this.handleGetCard();
      } catch (e) {
        toastMessage = {
          message: e,
          color: TOAST_COLOR.DANGER
        };
      } finally {
        this.setLoadingAddCard(false);
        this.setOpenModalAddCard(false);
        await openToast(toastMessage);
      }
    },
    async addNetsBankCard() {
      // let errorMessage = '';
      // try {
      //   const data = await Payment.onRegistration({
      //     mid: process.env.VUE_APP_NETS_MERCHANT_ID,
      //     muid: `${this.user.id}`
      //   });
      //   if (data.isSuccess) {
      //     const res = await this[ACTIONS.GET_NETS_MERCHANT_TOKEN]({
      //       t0102: data.result,
      //       deviceId: this.deviceId
      //     });
      //     if (res) {
      //       await this[ACTIONS.GET_LIST_NETS_CARD](this.deviceId);
      //       this.isOpenFormNetsPayment = !this.listNetsCard || this.listNetsCard.length === 0;
      //     } else {
      //       errorMessage = this.error;
      //     }
      //   } else {
      //     errorMessage = data.result;
      //   }
      //   if (errorMessage) {
      //     const alert = await alertController.create({
      //       header: this.$t('nets_payment_error'),
      //       message: errorMessage,
      //       buttons: [this.$t('close')]
      //     });
      //     await alert.present();
      //   }
      // } catch (error) {
      //   const alert = await alertController.create({
      //     header: this.$t('on_registration'),
      //     message: `ERROR ${JSON.stringify(error)}`,
      //     buttons: [this.$t('close')]
      //   });
      //   await alert.present();
      // }
    },
    async handleGetCard() {
      Promise.allSettled([
        this[ACTIONS.GET_LIST_NETS_CARD](this.deviceId),
        this[ACTIONS.GET_LIST_CUSTOMER_CARD](this.company.id),
        this[ACTIONS.GET_DEFAULT_CARD](this.company.id)
      ]).then(() => {
        if (!this.listNetsCard || this.listNetsCard.length < 1) {
          this.isOpenFormNetsPayment = false;
        }
        let listCardSort = [...this.cardList];
        const defaultCard = listCardSort.find((item) => item.id === this.defaultCardId);
        if (defaultCard) {
          listCardSort = listCardSort.filter((item) => item.id !== this.defaultCardId);
          listCardSort.unshift(defaultCard);
        }
        this.listCardSortDefault = listCardSort;
        this.setOpenLoadingInit(false);
      });
    },
    async enterPayment() {
      Clevertap.onUserProceedInvoices({
        companyName: this.company?.name?.trim(),
        invoices: this.selectedInvoices,
        totalAmountInvoices: this.totalInvoicesAmount.toFixed(2)
      });

      if (this.isDigitalPayment || this.isFullyPaidCustBalance) {
        this.setLoadingAddCard(true);
        await this.submitDigitalPayment();
        if (this.error) {
          this.setLoadingAddCard(false);
          this.alert('my-custom-class', 'payment_creation_failed', this.error.message, [
            {
              text: this.$t('OK'),
              handler: () => {
                this.$router.replace('/b2b/invoices/select-invoices');
              }
            }
          ]);
        } else {
          if (this.isPaynowSelected) {
            this.setLoadingAddCard(false);
            this.alert('my-custom-class', 'orderB2b.paynowInstruction', '', [
              {
                text: this.$t('OK'),
                handler: async () => {
                  await this.viewQrCode();
                  this.setDigitalPaymentSummaryOpen(true);
                }
              }
            ]);
          } else {
            this.setLoadingAddCard(false);
            this.setDigitalPaymentSummaryOpen(true);
          }
        }
      } else {
        await this.submitOtherPayment();
      }
    },
    async submitDigitalPayment() {
      const paramsCreatePayment = {
        paymentTypeId: this.isFullyPaidCustBalance ? INVOICE_PAYMENT_TYPE.CUSTOMER_CREDIT : this.paymentType,
        totalPaidAmount: parseFloat(this.totalPaidAmount.toFixed(2)),
        invoiceIDs: this.selectedInvoices.map((invoice) => invoice.id),
        balanceAllocationAmount: this.customerCreditAllocation,
        stripeCardId:
          this.isPaynowSelected || this.isFullyPaidCustBalance || this.paymentType === 7
            ? null
            : this.digitalCardIdSelected,
        stripeCustomerId: null
      };
      await this[ACTIONS.CREATE_AUTOMATIC_BATCH_PAYMENTS]({ paramsCreatePayment });
    },
    async submitOtherPayment() {
      await this[ACTIONS.SET_SELECTED_PAYMENT_INVOICES]({
        selectedInvoices: this.selectedInvoices,
        totalInvoiceAmount: this.totalInvoicesAmount,
        availableCreditAmount: this.maximumCreditAmount,
        overdueAmount: this.totalOverdue,
        overPayment: this.totalOverpayment,
        totalPaidAmount: this.totalPaidAmount,
        customerCreditAllocation: this.customerCreditAllocation
      });
      this.$router.push({
        path: '/b2b/invoices/payment-details',
        query: {
          'payment-type': this.paymentType
        }
      });
    },
    async viewQrCode() {
      if (isPlatform('ios')) {
        await this.$browser.open({
          toolbarColor: '#fff',
          presentationStyle: 'fullscreen',
          url: this.paymentSummary?.url
        });
      } else if (isPlatform('android')) {
        await this.$browser.open({
          toolbarColor: '#ffffff',
          presentationStyle: 'fullscreen',
          url: this.paymentSummary?.url
        });
      } else {
        window.location.href = this.paymentSummary?.url;
      }
    },
    cancelPaymentOrder() {
      this.alert('alert-cancel-payment', 'cancel_payment', 'cancel_payment_desc', [
        {
          text: this.$t('orderB2b.cancelStayButton'),
          role: 'cancel'
        },
        {
          text: this.$t('orderB2b.cancelConfirmButton'),
          handler: async () => {
            this.setDigitalPaymentSummaryOpen(false);
            this.$router.replace('/b2b/invoices/select-invoices');
            await apolloClient.mutate({
              mutation: cancelPrepayment,
              variables: {
                stripeCheckoutSessionId: this.paymentSummary.id
              }
            });
          }
        }
      ]);
    }
  }
});
</script>

<style lang="scss" scoped>
.i-help {
  color: #eb8c31;
  font-size: 22px;
}
.bor-15 {
  --border-radius: 15px;
}
ion-toggle {
  height: 32px;
  width: 50px;
  --handle-background: #fff;
  --handle-background-checked: #fff;
  --background-checked: #00676a;
  --handle-width: 27px;
  --handle-height: 40px;
  --handle-border-radius: 16px;
  --handle-max-height: 28px;
  --handle-spacing: 2px;
  /* Required for iOS handle to overflow the height of the track */
  overflow: visible;
  contain: none;
}
.line {
  width: 100%;
  height: 6px;
  background: #eeeded;
}
</style>
