<template>
  <ion-page style="background-color: #e5e5e5">
    <ion-header>
      <ion-toolbar class="toolbar-header">
        <ion-label class="fw-600 ml-2">{{ $t('payment_summary') }}</ion-label>
      </ion-toolbar>
    </ion-header>

    <ion-content>
      <div class="mt-5 mb-2 d-flex align-center justify-center flex-column">
        <ion-img :src="paymentStatusUpdate.icon" style="width: 80px" />
        <ion-label class="fs-4 fw-600 mt-2 mb-2" :class="paymentStatusUpdate.color">
          {{ paymentStatusUpdate.label }}
        </ion-label>
      </div>
      <ion-grid class="my-2 box px-2 py-2">
        <!-- // if paynow  -->
        <ion-row
          v-if="isPaynow && paymentStatusUpdate.value === 'pending'"
          class="border-bottom ion-justify-content-center mb-2"
        >
          <ion-text class="text-center mb-2 text-gray-900">{{
            $t('orderB2b.paymentPendingMessage')
          }}</ion-text>
          <ion-col size="12" class="text-center">
            <ion-text class="text-gray-900">{{ $t('orderB2b.paymentPendingHeadline') }}</ion-text>
          </ion-col>
          <ion-col size="12" class="text-center mb-2">
            <ion-text class="fw-600">{{ `${paymentExpiresTime}` }}</ion-text>
          </ion-col>
          <ion-col size="12" class="d-flex justify-center align-center mb-2">
            <ion-icon :icon="timerOutline" class="mr-1 text-error-600" style="font-size: 1.5rem"></ion-icon>
            <ion-text class="fw-600 text-error-600"
              >{{ $t('orderB2b.paymentExpireIn') }}
              {{ remainingTime }}
            </ion-text>
          </ion-col>
        </ion-row>
        <!-- else  -->
        <ion-row class="mb-2 mx-1 ion-justify-content-between">
          <ion-label class="text-gray-700">{{ `Invoice(s) Amount` }}</ion-label>
          <ion-label style="float: right" class="fw-600 text-gray-700">{{ invoiceAmountValue }}</ion-label>
        </ion-row>
        <ion-row class="mb-2" v-for="(invoice, i) in invoicesData" :key="i">
          <ion-col push="0.3">
            <ion-label class="text-gray-700">{{ invoice.label }}</ion-label>
          </ion-col>
          <ion-col size="auto">
            <ion-label class="fw-600 text-gray-700">{{ invoice.value }}</ion-label>
          </ion-col>
        </ion-row>
        <ion-row
          v-for="(item, idx) in dataSummary"
          :key="idx"
          class="mb-2"
          :class="{ 'border-bottom': [1, 2, 4].includes(idx) }"
        >
          <ion-col>
            <ion-label
              class="text-gray-700"
              :class="{
                'text-primary-green-600': idx === 0,
                'fw-700': idx == 5,
                'fw-500': [1, 2, 0].includes(idx)
              }"
              >{{ item.label }}</ion-label
            >
          </ion-col>
          <ion-col size="auto">
            <ion-text
              class="fw-600 text-gray-700"
              :class="idx === 5 ? `${paymentStatusUpdate.color}` : idx === 0 ? 'text-primary-green-600' : ''"
            >
              {{ item?.value }}</ion-text
            >
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col> </ion-col>
          <ion-col size="auto">
            <ion-text class="fw-700 text-gray-700">
              {{ priceFormatter(currencySymbol, totalPaidAmount) }}</ion-text
            >
          </ion-col>
        </ion-row>
        <ion-button
          class="my-2"
          @click="
            isPaynow && paymentStatusUpdate.value !== 'approved'
              ? $emit('viewQrCode')
              : $emit('backToInvoice')
          "
          expand="block"
          color="primary"
        >
          <ion-label class="text-capitalize">
            {{
              isPaynow && paymentStatusUpdate.value !== 'approved'
                ? $t('orderB2b.viewQRCode')
                : $t('back_to_invoice')
            }}
          </ion-label>
        </ion-button>
        <ion-button
          class="mb-4"
          @click="
            isPaynow && paymentStatusUpdate.value !== 'approved'
              ? $emit('cancelPaymentOrder')
              : $emit('backToHome')
          "
          fill="outline"
          expand="block"
        >
          <ion-label class="text-capitalize">
            {{
              isPaynow && paymentStatusUpdate.value !== 'approved' ? $t('cancel_payment') : $t('back_to_home')
            }}
          </ion-label>
        </ion-button>
      </ion-grid>
    </ion-content>
  </ion-page>
</template>
<script>
import { apolloClient } from '@/main';
import { PAYMENT_STATUS_ICONS, PAYMENT_STATUS_INVOICE_PAYNOW } from '@/modules/b2b/constants';
import { getBatchPaymentDetail } from '@/modules/b2b/services/graphql';
import { INVOICE_PAYMENT_TYPE } from '@/modules/shared/constants/';
import { digitalPaymentTypeLabel } from '@/modules/shared/utils/';
import { priceFormatter } from '@/utils/';
import dayjs from 'dayjs';
import { timerOutline } from 'ionicons/icons';
import moment from 'moment';
import { defineComponent, inject, onMounted, ref } from 'vue';
import { useI18n } from 'vue-i18n';
export default defineComponent({
  name: 'payment-summary-digital',
  emits: ['backToInvoice', 'backToHome', 'cancelPaymentOrder', 'viewQrCode'],
  props: {
    paymentSummary: {
      type: Object,
      default: () => {}
    },
    currencySymbol: {
      type: String,
      default: 'S$'
    },
    invoices: {
      type: Array,
      default: () => []
    },
    appliedCredit: {
      type: Number,
      default: 0
    },
    totalPaidAmount: {
      type: Number,
      default: 0
    },
    grandTotal: {
      type: Number,
      default: 0
    },
    paymentType: {
      type: Number,
      default: INVOICE_PAYMENT_TYPE.CREDIT_CARD
    }
  },
  setup(props) {
    const { t } = useI18n();
    const storage = inject('$storage');
    const isPaynow = ref(props.paymentType === INVOICE_PAYMENT_TYPE.PAYNOW);
    const isCustCredit = ref(props.paymentType === INVOICE_PAYMENT_TYPE.CUSTOMER_CREDIT);
    const currentPaymentStatus = ref(props.paymentSummary?.details?.batchPayment?.status);
    const digitalPaymentStatusses = ref([
      {
        label: t('payment_success'),
        icon: PAYMENT_STATUS_ICONS.SUCCESSFUL,
        color: 'text-primary-green-600',
        btmLabel: t('paid').toUpperCase(),
        value: PAYMENT_STATUS_INVOICE_PAYNOW.APPROVED
      },
      {
        label: t('payment_failed'),
        icon: PAYMENT_STATUS_ICONS.FAILED,
        color: 'text-error-600',
        btmLabel: t('failed').toUpperCase(),
        value: PAYMENT_STATUS_INVOICE_PAYNOW.FAILED
      },
      {
        label: t('payment_pending'),
        icon: PAYMENT_STATUS_ICONS.PENDING,
        color: 'text-primary-orange-500',
        btmLabel: t('pending').toUpperCase(),
        value: PAYMENT_STATUS_INVOICE_PAYNOW.PENDING
      }
    ]);
    const paymentStatusUpdate = ref(null);
    if (isCustCredit.value) {
      paymentStatusUpdate.value = digitalPaymentStatusses.value[0];
    } else {
      paymentStatusUpdate.value = digitalPaymentStatusses.value.find(
        (item) => item.value === currentPaymentStatus.value
      );
    }
    const setPaymentStatusSelected = (value) => {
      paymentStatusUpdate.value = digitalPaymentStatusses.value.find((item) => item.value === value);
    };
    const paymentMethod = ref(digitalPaymentTypeLabel[props.paymentType]);
    const invoicesData = props.invoices.map((invoice) => {
      return {
        label: invoice.invoiceNumber,
        value: priceFormatter(props.currencySymbol, invoice.outstandingAmount)
      };
    });
    const invoiceAmount = props.invoices.reduce((acc, invoice) => {
      const sum = acc + invoice.outstandingAmount ?? 0;
      return sum;
    }, 0);
    const invoiceAmountValue = priceFormatter(props.currencySymbol, invoiceAmount);
    const paymentExpiresTime = isPaynow.value
      ? moment.unix(props.paymentSummary.expires_at).format('HH:mm:ss, DD MMM YYYY')
      : '';
    const remainingTime = ref(null);
    const getPaynowStatus = async () => {
      const { tenant } = await storage.getUser();
      const { data } = await apolloClient.query({
        query: getBatchPaymentDetail,
        variables: {
          tenantId: tenant.id,
          batchPaymentId: props.paymentSummary?.details?.batchPayment?.id
        }
      });
      if (data?.getBatchPaymentDetail?.status) {
        setPaymentStatusSelected(data?.getBatchPaymentDetail?.status);
        if (data?.getBatchPaymentDetail?.status === PAYMENT_STATUS_INVOICE_PAYNOW.PENDING) {
          const countDownInterval = setInterval(() => {
            const diffTime =
              dayjs.unix(props.paymentSummary.expires_at).diff(dayjs(), 'milliseconds') > 0
                ? dayjs.duration(dayjs.unix(props.paymentSummary.expires_at).diff(dayjs()))
                : undefined;
            if (diffTime) {
              remainingTime.value = `${diffTime.format('HH')} HRS ${diffTime.format(
                'mm'
              )} MINS ${diffTime.format('ss')} SECS`;
            }
            if (diffTime.format('HH:mm:ss') === '00:00:00') {
              clearInterval(countDownInterval);
            }
          }, 1000);

          // start checking order status
          const paymentStatusChecker = setInterval(async () => {
            const { data } = await apolloClient.query({
              query: getBatchPaymentDetail,
              variables: {
                tenantId: tenant.id,
                batchPaymentId: props.paymentSummary?.details?.batchPayment?.id
              }
            });
            const status = data?.getBatchPaymentDetail?.status;
            if (
              status === PAYMENT_STATUS_INVOICE_PAYNOW.APPROVED ||
              status === PAYMENT_STATUS_INVOICE_PAYNOW.FAILED
            ) {
              setPaymentStatusSelected(status);
              dataSummary.value[5].value = paymentStatusUpdate.value.btmLabel;
              clearInterval(paymentStatusChecker);
              clearInterval(countDownInterval);
            }
          }, 1000);
        }
      }
    };

    const valueDataSummary = (value) => priceFormatter(props.currencySymbol, value);
    const transactionDate = isCustCredit.value
      ? moment(new Date(props.paymentSummary?.details?.customerCreditBatchPayment?.updated_at)).format(
          'DD MMM YYYY'
        )
      : moment(new Date(props.paymentSummary?.details?.batchPayment?.updated_at)).format('DD MMM YYYY');
    const dataSummary = ref([
      {
        label: t('applied_credits'),
        value: valueDataSummary(props.appliedCredit)
      },
      {
        label: t('sub_total'),
        value: valueDataSummary(props.grandTotal)
      },
      {
        label: t('total_payment'),
        value: valueDataSummary(props.totalPaidAmount)
      },
      {
        label: t('payment_method'),
        value: paymentMethod.value ?? 'Debit'
      },
      {
        label: t('transaction_date'),
        value: transactionDate
      },
      {
        label: t('total'),
        value: paymentStatusUpdate.value.btmLabel
      }
    ]);
    onMounted(async () => {
      if (isPaynow.value) await getPaynowStatus();
    });
    return {
      invoicesData,
      isPaynow,
      invoiceAmountValue,
      dataSummary,
      paymentStatusUpdate,
      timerOutline,
      paymentExpiresTime,
      priceFormatter,
      remainingTime
    };
  }
});
</script>
<style lang="scss" scoped>
.f-icon {
  font-size: 3rem;
}
ion-content {
  --ion-background-color: #e5e5e5;
}

ion-button {
  width: 95%;
  margin: 0 auto;
}
.box {
  border: 1px solid var(--ion-color-text-gray-300);
  background-color: #fff;
  box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.2);
  border-radius: 10px !important;
  width: 94%;
  margin: 0 auto;
}
</style>
